import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

type Params = {
  limit: string | string[];
  topicId: string | string[];
  fetchCall: (page: number) => Promise<any>;
  isArticlePage?: boolean;
  disabled?: boolean;
};

const useJournalSlugPagesData = ({
  limit,
  topicId,
  fetchCall,
  isArticlePage,
  disabled,
}: Params) => {
  const router = useRouter();
  const { page = '1' } = router?.query;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const onChangePage = (e: React.ChangeEvent<unknown>, p: number) => {
    const path = router.asPath?.split("?")?.at(0);
    const params = new URLSearchParams({
      limit: `${limit || '6'}`,
      page: `${p}`,
      ...(topicId && { topic: `${topicId}` }),
    }).toString();
    router.push(`${path}?${params}`);
  };
  const count = Math.ceil(
    totalItems / (Number.isNaN(+limit) ? 6 : Number(limit))
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const { items: newItems, totalItems: tot } = await fetchCall(
        Number(`${page}`)
      );

      setItems(newItems);
      setTotalItems(tot);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCall, page]);

  useEffect(() => {
    if (isArticlePage || disabled) return;

    fetchData();
  }, [disabled, fetchData, isArticlePage]);

  return { onChangePage, error, isLoading, totalItems, items, count };
};

export default useJournalSlugPagesData;
