import axios from "axios";
import useInfiniteScroll from "@hooks/useInfiniteScroll";
import { useRouter } from "next/router";
import { useCallback } from "react";
import useJournalSlugPagesData from "./useJournalSlugPagesData";

type Params = {
  isArticlePage?: boolean;
  topicId?: string | string[];
  categoryId?: string;
  infiniteScroll?: boolean;
};

const useJournalSlugArticles = ({
  isArticlePage,
  topicId,
  categoryId,
  infiniteScroll,
}: Params) => {
  const router = useRouter();
  const { limit = '100' } = router.query;

  const fetchArticlesData = useCallback(
    async (page: number) => {
      if (isArticlePage) return;

      try {
        const path = topicId
          ? `/api/next-journal/topic/${topicId}/${categoryId}/`
          : `/api/next-journal/${categoryId}/`;
        const response = await axios.get(path, {
          params: { locale: router.locale, page, limit },
        });

        const { articles: foundArticles = [], totalItems } = response?.data;

        return { items: foundArticles, totalItems };
      } catch (error) {
        console.error({error});
      }
    },
    [categoryId, isArticlePage, limit, router.locale, topicId]
  );

  const disabledInfiniteScroll = isArticlePage || !infiniteScroll;
  const disabledPages = isArticlePage || infiniteScroll;

  const infiniteScrollData = useInfiniteScroll(
    fetchArticlesData,
    disabledInfiniteScroll
  );
  const pagesData = useJournalSlugPagesData({
    fetchCall: fetchArticlesData,
    disabled: disabledPages,
    topicId,
    isArticlePage,
    limit,
  });

  return {
    infiniteScrollTarget: null,
    count: 0,
    onChangePage: (...args: unknown[]) => {
      return 0;
    },
    ...(infiniteScroll ? infiniteScrollData : pagesData),
  };
};

export default useJournalSlugArticles;
