import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import axios from "axios";
import { DrupalNode } from "next-drupal";
import { useRouter } from "next/router";
import { FC, useCallback, useEffect, useState } from "react";
import ArticleCard from "../ArticleCard/ArticleCard";
import ArticleCardCarousel from "../ArticleCardCarousel/ArticleCardCarousel";
import Spinner from "../Spinner/Spinner";

type Props = {
  relatedArticles: DrupalNode[];
};

const RelatedArticlesCarousel: FC<Props> = ({ relatedArticles }) => {
  const router = useRouter();
  const [fetchedArticles, setFetchedArticles] = useState<DrupalNode[]>([]);
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const [loading, setLoading] = useState(false);

  const getResources = useCallback(async () => {
    try {
      setLoading(true);
      const articlePromises = relatedArticles.map(({ id }) => axios.get(`/api/next-journal/related/${id}`, {
        params: { locale: router.locale },
      }));
      const articleResponses = await Promise.allSettled(articlePromises);
      const articles: DrupalNode[] = articleResponses?.reduce((acc, res) => {
        if (res?.status !== 'fulfilled') return acc;
        if (!('value' in res) || !('data' in res?.value)) return acc;
        return [...acc, res.value.data as DrupalNode];
      }, []);
      setFetchedArticles(articles);
    } finally {
      setLoading(false);
    }
  }, [relatedArticles, router.locale]);

  useEffect(() => {
    getResources();
  }, [relatedArticles, getResources]);

  const showCarousel = fetchedArticles?.length > 2 || isMobile;

  if (loading) {
    return <Spinner active />;
  }

  return (
    <Container sx={showCarousel ? {} : {display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '2rem'}} maxWidth={false}>
      {showCarousel ? (
        <ArticleCardCarousel articles={fetchedArticles} />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "6%",
            width: "100%",
            height: "400px",
            maxWidth: '400px',
            "& a": {
              height: "100%",
              width: "100%",
              "& .MuiBox-root": { height: "400px", width: "400px" },
            },
            mb: "8rem",
          }}
        >
          {fetchedArticles.map((article) => (
            <ArticleCard sx={{
              paddingBottom: '0',
            }} key={article?.id} article={article} />
          ))}
        </Box>
      )}
    </Container>
  );
};

export default RelatedArticlesCarousel;
