import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import { FC, useState } from "react";
import TagButtons from "../TagButtons/TagButtons";
import { DrupalNode } from "next-drupal";
import { useTranslation } from "next-i18next";

type Props = {
  articlesPerCategory: {
    name: string,
    articles: DrupalNode[],
    url: string;
  }[],
};

const TagsDialog: FC<Props> = ({ articlesPerCategory }) => {
  const { t } = useTranslation();
  const [categoriesOpened, setCategoriesOpened] = useState(false);
  const openCategories = () => {
    setCategoriesOpened(true);
  };
  const closeCategories = () => {
    setCategoriesOpened(false);
  };

  return (
    <>
      <Button
        id="categoriesModal"
        variant={"outlined"}
        onClick={openCategories}
        sx={{
          lineHeight: "2 !important",
          height: "fit-content !important",
          mb: "4rem",
        }}
      >
        {t('next-journal.index.tags-dialog.title')}
      </Button>

      <Dialog
        open={categoriesOpened}
        onClose={closeCategories}
        aria-labelledby="filter-by-category-dialog"
        sx={{
          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            height: "calc(100% - 120px)",
            width: "100%",
            top: "30px",
          },
        }}
      >
        <DialogContent>
          <Typography
            sx={{ fontSize: "1rem", mb: "4rem", textAlign: "center" }}
          >
            {t('next-journal.index.tags-dialog.title')}
          </Typography>

          <TagButtons articlesPerCategory={articlesPerCategory} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TagsDialog;
