import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { DrupalNode } from "next-drupal";
import axios from "axios";

type Params = {
  topicId?: string | string[];
  node?: DrupalNode;
  isArticlePage?: boolean;
  categoryTitle?: string;
};

const useJournalSlugTopicData = ({
  topicId,
  isArticlePage,
  node,
  categoryTitle,
}: Params) => {
  const { locale } = useRouter();
  const [topicName, setTopicName] = useState("");
  const relatedTopicNodes = useMemo(
    () => (isArticlePage ? (node?.field_article_ambito as DrupalNode[]) : []),
    [isArticlePage, node?.field_article_ambito]
  );
  const pageTitle = useMemo(
    () => (topicId ? topicName : categoryTitle?.replace("-", " ")),
    [categoryTitle, topicId, topicName]
  );

  const fetchTopicData = useCallback(
    async (signal: AbortSignal) => {
      if (isArticlePage) return;
      if (!topicId) return;

      try {
        const topicResponse = await axios.get(
          `/api/next-journal/topic/${topicId}`,
          {
            params: { locale, topicId },
            signal,
          }
        );
        const { name: topicName = "" } = topicResponse?.data as DrupalNode;

        setTopicName(topicName);
      } catch (error) {
        console.error({error});
      }
    },
    [isArticlePage, locale, topicId]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchTopicData(controller.signal);
    return () => {
      controller.abort();
    };
  }, [fetchTopicData]);

  return { topicName, relatedTopicNodes, pageTitle };
};

export default useJournalSlugTopicData;
