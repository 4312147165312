

import { useSession } from 'next-auth/react';

import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Row from "@components/Modules/Utils/Row/Row";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { theme } from '@styles/mui/theme';
import { useTranslation } from 'next-i18next';

export const AuthSection: React.FC = () => {
  const { status, data } = useSession();
  const {t} = useTranslation();
  const isAuthenticated: boolean = status === 'authenticated'
  return (
    <Container maxWidth={false} sx={{bgcolor: theme.palette.grey[100]}}>
      <Row justifyContent='center' rowSpacing={16} pt={80} pb={isAuthenticated ? 40 : 80} >
        <Grid item xs={12} lg={8} textAlign={isAuthenticated ? 'left' : 'center'}>
          {isAuthenticated ? (
            <>
              <Typography variant="h5" mb={8}>
                {t('authentication.authSection.loggedMessage')}:
              </Typography>

              <Typography variant="h6" mb={32} color={theme.palette.grey[600]}>
                {data?.user.name}: ({data?.user.email})
              </Typography>
              <LogoutButton />
            </>
          ) : (
            <>
              <Typography variant="h4" mb={40}>
                {t('authentication.authSection.protectedPage')},<br />{t('authentication.authSection.protectedPageAction')}
              </Typography>
              <LoginButton />
            </>
          )}
        </Grid>
      </Row>
    </Container>
  )
}

export default AuthSection