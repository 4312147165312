import Button from "@mui/material/Button";
import { signOut } from 'next-auth/react';
import { useTranslation } from "next-i18next";

import { NextRouter, useRouter } from "next/router";

export const LogoutButton: React.FC = () => {
  const router: NextRouter = useRouter();
  const {t} = useTranslation();

  return (
    <Button
      variant="contained"
      size="small"
      onClick={() => signOut({ callbackUrl: router.asPath })}
    >
      {t('authentication.logout')}
    </Button>
  )
}

export default LogoutButton