import { renderParagraphs } from "@helper/paragraph";
import { NodeProps } from "@model/node.model";
import AuthSection from "@components/Authentication/AuthSection";
import Breadcrumbs from '@components/Common/Breadcrumbs/Breadcrumbs';

const NodeBasicPage: React.FC<NodeProps> = ({ node, isProtectedPath }) => {
  const fieldParagraph = node?.field_paragraph ? renderParagraphs(node) : null;

  return (
    <>
      <Breadcrumbs node={node}/>
      {isProtectedPath && <AuthSection /> }
      {fieldParagraph}
    </>
  );
}

export default NodeBasicPage