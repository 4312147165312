import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { DrupalNode } from "next-drupal";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import RelatedArticlesCarousel from "../RelatedArticlesCarousel/RelatedArticlesCarousel";

type Props = {
  relatedArticles: DrupalNode[];
};

const ViewAllButton: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Button id="discover-articles" variant="outlined" href={"/next-journal/"}>
        {t("next-journal.article.viewAll")}
      </Button>
    </Box>
  );
};

const RelatedArticlesSection: FC<Props> = ({ relatedArticles }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const noArticles = !relatedArticles || !relatedArticles?.length;

  return (
    <>
      {noArticles ? (
        <></>
      ) : (
        <Box sx={{ py: "6rem" }}>
          <Typography
            sx={{
              width: "100%",
              textAlign: "center",
              textTransform: "Capitalize",
              color: "#001a70",
              fontWeight: "400",
              transition: "none",
              fontSize: isMobile ? "1.75rem" : "2.25rem",
              lineHeight: isMobile ? "2.25rem" : "3rem",
              marginBottom: isMobile ? 20 : 10,
            }}
          >
            {t("next-journal.article.relatedArticles")}
          </Typography>

          <RelatedArticlesCarousel relatedArticles={relatedArticles} />
        </Box>
      )}
    </>
  );
};

export default RelatedArticlesSection;
