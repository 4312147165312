import Button from "@mui/material/Button";

import { signIn } from 'next-auth/react';
import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from "next/router";

export const LoginButton: React.FC = () => {
  const router: NextRouter = useRouter()
  const {t} = useTranslation();
  return (
    <Button
      variant="contained"
      onClick={() => {
        signIn(
          'azure-ad',
          { callbackUrl: router.asPath },
          { prompt: 'login' },
        );
      }}
    >
      {t('authentication.login')}
    </Button>
  )
}

export default LoginButton