import Container from "@mui/material/Container";
import { renderParagraphs } from "@helper/paragraph";
import { NodeProps } from "@model/node.model";
import Breadcrumbs from '@components/Common/Breadcrumbs/Breadcrumbs';
import RelatedArticlesSection from "@components/Modules/NextJournal/RelatedArticlesSection/RelatedArticlesSection";
import TopicChipsSection from "@components/Modules/NextJournal/TopicChipsSection/TopicChipsSection";

const NodeArticle: React.FC<NodeProps> = ({ node, topics }) => {
  const paragraphs = node?.field_paragraph ? renderParagraphs(node) : null;

  return (
    <>
      <Breadcrumbs node={node} />

      {paragraphs}

      {topics?.length ? (
        <Container maxWidth={false} sx={{pb: '4rem', pt:'6rem'}}>
          <TopicChipsSection articleTopics={topics} />
        </Container>
      ) : null}
      
      {node?.field_related?.length ? <RelatedArticlesSection relatedArticles={node?.field_related ?? []} /> : null}
    </>
  );
}

export default NodeArticle