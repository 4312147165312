import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { renderParagraphs } from "@helper/paragraph";
import { NextRouter, useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { NodeProps } from "@model/node.model";
import Breadcrumbs from '../Common/Breadcrumbs/Breadcrumbs';
import ArticleCardGrid from "../Modules/NextJournal/ArticleCardGrid/ArticleCardGrid";
import ContentHeader from "../Modules/NextJournal/ContentHeader/ContentHeader";
import Spinner from "../Modules/NextJournal/Spinner/Spinner";

const TaxonomyTermImplantType: React.FC<NodeProps> = ({ node }) => {
  const router: NextRouter = useRouter();
  const isMobile: boolean = useMediaQuery(useTheme().breakpoints.down("lg"));
  const [items, setItems] = useState(null)
  const paragraphs = node?.field_paragraphs ? renderParagraphs(node) : null;
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const fetchImplants = async () => {
      try {
        setLoading(true)
        const resp = await fetch(`/api/implants/${node.id}?locale=${router.locale}`)
        .then(data => data.json())
        setItems(resp)
      } catch (error) {
        console.error("Error to fetch implants:", error);
      } finally {
        setLoading(false)
      }
      
    }
    fetchImplants()
  }, [node, router])

  return (
    <>
      <Breadcrumbs node={node} />

      {paragraphs}
      <Container maxWidth="xl" sx={{pb:{sm: 300, lg: 120}}}>
        <Box sx={{ pt: { xs: '3rem', lg: '10rem' } }}>
          <ContentHeader isMobile={isMobile} title={node.name}/>
        </Box>
        {items && <ArticleCardGrid articles={items} cardVariant={'implants'} />}
        {loading && <Spinner active />}
      </Container>
    </>
  );
}

export default TaxonomyTermImplantType
