import { FC } from "react";
import { DrupalNode } from "next-drupal";
import useTheme from "@mui/system/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "next-i18next";
import CustomChip from "../CustomChip/CustomChip";

type Props = {
  articleTopics: DrupalNode[];
};

const TopicChipsSection: FC<Props> = ({ articleTopics }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));
  const {t} = useTranslation();

  if (!articleTopics?.length) return <></>;

  return (
    <Stack
      width="100%"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="start"
    >
      <Typography
        sx={
          isMobile
            ? {
                width: "100%",
                textAlign: "start",
                mb: "1rem",
              }
            : {
              mr: "4rem"
            }
        }
      >
        {t('next-journal.article.topic-tag')}
      </Typography>

      <Stack
        flexDirection="row"
        rowGap="1rem"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="start"
      >
        {articleTopics?.map((topic) => (
          <CustomChip
            key={topic?.id}
            href={`/next-journal/topic/?id=${topic?.id}`}
            label={topic?.name}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default TopicChipsSection;
