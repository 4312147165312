import { useState, useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (fetchCall: (page: number) => Promise<{items: unknown[], totalItems: number}>, disabled?: boolean) => {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(1);

  const fetchData = useCallback(async () => {
    if (isLastPage || isLoading) return;
    setIsLoading(true);
    setError(null);
  
    try {
      const {items: newItems, totalItems: tot} = await fetchCall(nextPage);
  
      setItems(prevItems => [...prevItems, ...(newItems && newItems)]);
      setTotalItems(tot);
      setIsLastPage(!newItems?.length);
      setNextPage(prevPage => prevPage + 1);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCall, isLastPage, isLoading, nextPage]);

  const observerTarget = useRef(null);

  const observerCallback = useCallback(([observed]:  IntersectionObserverEntry[]) => {
    if (!observed || !observed?.isIntersecting) return;
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (disabled) return;
    const observer = new IntersectionObserver(observerCallback,
      { threshold: 1 }
    );

    const target = observerTarget.current;
    
    if (!target) return;

    observer.observe(target);

    const cleanUp = () => {
      observer.unobserve(target);
    };

    return cleanUp;
  }, [observerCallback, observerTarget, disabled]);

  useEffect(() => {
    if (disabled) return;
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {items, isLoading, error, infiniteScrollTarget: observerTarget, totalItems};
};

export default useInfiniteScroll;