import Button from "@mui/material/Button";
import Stack, {StackProps} from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/system/useTheme";
import { DrupalNode } from "next-drupal";
import Link from "next/link";
import { FC, Fragment } from "react";

type ArticlesPerCategory = {
  name: string;
  articles: DrupalNode[];
  url: string;
};

type Props = StackProps & {
  articlesPerCategory: ArticlesPerCategory[];
};

const TagButtons: FC<Props> = ({ articlesPerCategory, ...props }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("lg"));

  return (
    <Stack
      {...props}
      direction={isMobile ? "column" : "row"}
      flexWrap="wrap"
      spacing="20px"
    >
      {articlesPerCategory.map(({ name, articles, url }) => (
        <Fragment key={name}>
          {!!articles.length ? (
            <Button
              id={name}
              LinkComponent={Link}
              variant={"outlined"}
              href={url}
              sx={{
                lineHeight: "2 !important",
                height: "fit-content !important",
              }}
            >
              {name}
            </Button>
          ) : (
            <></>
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export default TagButtons;
